// import $ from 'jquery'
import moment from 'moment'
import tippy from '../../vendor/tippy/tippy.min'
import i18n from '../../i18n.js'
import QRCode from 'qrcode-svg'
import _ from 'lodash'

import ProgressButton from '../../features/progress_button/progress_button'

class PageSiDashboard {
  requestChangeFadeInterval = 500
  employeeChangeFadeInterval = 500

  onLoad() {

    this.waitingForMessages = {}
    this.waitingForOcpCommands = {}

    this.storeId = $('[data-store-id]').data('store-id')
    if (this.storeId === undefined) {
      this.storeId = ''
    }

    this.siDeskId = $('[data-si-desk-id]').data('si-desk-id')
    if (this.siDeskId === undefined) {
      this.siDeskId = ''
    }

    this.selectedLanguage = $('[data-default-language]').data('default-language')
    if (this.selectedLanguage === undefined) {
      this.selectedLanguage = 'de'
    }

    vex.defaultOptions.className = 'vex-theme-flat-attack'
    vex.dialog.buttons.YES.text = i18n[window.currentLocale].views.common.ok
    vex.dialog.buttons.NO.text = i18n[window.currentLocale].views.common.cancel

    this.pollInterval = 2000
    this.heavyPollInterval = 5 * 60000
    this.eventPollInterval = 1000 // See skipping logic below. Actual update logic is every 30s

    this.eventPollSkip = 0
    this.eventPollSkipMax = 10

    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    this.requests = []
    this.employees = []

    this.heavyPoll()
    this.eventFeedPoll()
    this.poll()

    this.addRequestActionHandlers()
    this.addEmployeeActionHandlers()
    this.addVisualSwitchHandlers()
    this.addEventHandlers()
    this.addOfflineTerminalTooltips()
  }

  eventFeedPoll() {
    if (this.eventPollTimeout) {
      clearTimeout(this.eventPollTimeout)
    }
    if (!this.eventPollSkip) {
      if ($('.si-dashboard-events').is(':visible')) {
        this.eventPollSkip = this.eventPollSkipMax
        $.ajax({
          url: `/areas/si/data_events?shop_id=${this.storeId}&si_desk_id=${this.siDeskId}}`,
          timeout: 3000,
          success: data => {
            const feedData = this.processEventFeedData(data.feed)
            this.updateEvents(feedData)
          }
        })
        .fail(() => {
          console.error('SI dashboard events poll iteration failed')
        })
        .always(() => {
        })
      }
    } else {
      this.eventPollSkip--
    }

    this.eventPollTimeout = setTimeout(() => this.eventFeedPoll(), this.eventPollInterval)
  }

  heavyPoll() {
    if (this.heavyPollTimeout) {
      clearTimeout(this.heavyPollTimeout)
    }

    $.ajax({
      url: `/areas/si/data_heavy?shop_id=${this.storeId}&si_desk_id=${this.siDeskId}}`,
      timeout: 3000,
      success: data => {

        this.shop = data.shop
        this.routingStrategies = data.routing_strategies
        this.floors = data.floors

        this.infoterminalsOffline = _.filter(data.infoterminals, it => !it.online)

        this.infoterminals = {}
        _.each(data.infoterminals, it => {
          this.infoterminals[it.id] = it
        })

        this.si_desks = {}
        _.each(data.si_desks, it => {
          it.id = it[0]
          it.label = it[1]
          it.floor = it[2]
          this.si_desks[it.id] = it
        })

        this.updateOfflineTerminalsArea()

        const userSmartwatches = {}
        _.each(data.smartwatches, sw => {
          sw.id = sw[0]
          sw.user_id = sw[1]
          sw.battery_status = sw[2]
          sw.has_technical_problems = false
          userSmartwatches[sw.user_id] = sw
        })

        const userMobileSessions = {}
        _.each(data.mobile_sessions, s => {
          if (!userMobileSessions[s.user_id]) {
            userMobileSessions[s.user_id] = []
          }
          userMobileSessions[s.user_id].push(s)
        })

        this.userMap = {}
        this.userSmartwatches = userSmartwatches
        this.userMobileSessions = userMobileSessions

        _.each(data.users, u => {
          const sw = this.userSmartwatches ? this.userSmartwatches[u.id] : null
          u.battery = sw && sw.battery_status ? sw.battery_status + '%' : ''
          u.mobile_sessions = this.userMobileSessions[u.id] || []

          let sduration = moment.duration(moment().diff(moment(u.status_changed)))
          u.status_changed_minutes_ago = sduration.asMinutes()

          this.userMap[u.id] = u
        })

        this.users = data.users

        this.updateEmployees(this.users, this.floors)
      }
    })
    .fail(() => {
      console.error('SI dashboard heavy poll iteration failed')
    })
    .always(() => {
    })

    this.heavyPollTimeout = setTimeout(() => this.heavyPoll(), this.heavyPollInterval)
  }

  poll() {
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout)
    }

    const msgIds = Object.keys(this.waitingForMessages)
    const cmdIds = Object.keys(this.waitingForOcpCommands)

    $.ajax({
      url: `/areas/si/data?shop_id=${this.storeId}&si_desk_id=${this.siDeskId}&messages=${msgIds.join(',')}&ocp_commands=${cmdIds.join(',')}`,
      timeout: 3000,
      success: data => {

        if (data.poll_interval) {
          this.pollInterval = data.poll_interval
        }

        _.each(data.ocp_commands, cmd => {
          if (cmd.is_expired) {
            delete this.waitingForOcpCommands[cmd.id]
            this.resetWatchBtn.resetProgress()


            vex.dialog.alert({
              message: `${cmd.description} is expired and might not have been done.`
            })

            return
          }

          if (cmd.status == 'new') {
            return
          }

          delete this.waitingForOcpCommands[cmd.id]
          this.resetWatchBtn.resetProgress()

          if (cmd.status == 'failed') {
            vex.dialog.confirm({
              message: `${cmd.description} failed. Would you like to see the detailed log?`,
              callback: (value) => {
                if (value) {
                  vex.dialog.alert({
                    unsafeMessage: `<pre class='ocp-cmd-log'>${cmd.log}</pre>`
                  })
                }
              }
            })
          } else if (cmd.status == 'done') {
            vex.dialog.alert({
              message: `${cmd.description} finished successfully`
            })
          }
        })

        _.each(data.messages, m => {
          m.id = m[0]
          m.msg_type = m[1]
          m.status = m[2]
          m.target_user = m[3] && this.userMap[m[3]] ? this.userMap[m[3]] : null
          m.resolved_user = m[4] && this.userMap[m[4]] ? this.userMap[m[4]] : null
          m.has_technical_problems = false

          if (m.status == 'new') {
            return
          }

          delete this.waitingForMessages[m.id]
          if (m.msg_type == 'ring') {
            let text = m.status == 'resolved' ? 'is_coming' : 'is_not_coming'
            if (m.has_technical_problems) {
              text = 'technical_problems'
            }
            this.showEmployeeAlert(m.has_technical_problems ? null : m.target_user.avatar_url, m.target_user, text,
                                   m.has_technical_problems || !m.status == 'resolved' ? 'busy' : null,
                                   m.has_technical_problems ? 'problem' : 'message')
            this.ringBtn.resetProgress(this.ringBtn.topEl.find(`[data-entity-id=${m.target_user.id}] .progress-btn`))
          } else if (m.msg_type == 'call') {
            let text = m.status == 'resolved' ? 'is_ready_to_accept_call' : 'is_not_ready_to_accept_call'
            if (m.has_technical_problems) {
              text = 'technical_problems'
            }
            this.showEmployeeAlert(m.has_technical_problems ? null : m.target_user.avatar_url, m.target_user, text,
                                   m.has_technical_problems || !m.status == 'resolved' ? 'busy' : null,
                                   m.has_technical_problems ? 'problem' : 'message')
            this.callBtn.resetProgress(this.callBtn.topEl.find(`[data-entity-id=${m.target_user.id}] .progress-btn`))
          } else if (m.msg_type == 'check') {
            let text = m.status == 'resolved' ? 'is_free' : 'is_busy'
            if (m.has_technical_problems) {
              text = 'technical_problems'
            }
            this.showEmployeeAlert(null, m.target_user, text,
                                   m.status == 'resolved' ? 'free' : 'busy', m.has_technical_problems ? 'problem' : 'message')
            this.checkBtn.resetProgress(this.checkBtn.topEl.find(`[data-entity-id=${m.target_user.id}] .progress-btn`))
          } else if (m.msg_type == 'ring_all') {
            if (m.status == 'resolved') {
              this.showEmployeeAlert(m.resolved_user.avatar_url, m.resolved_user, 'is_coming', null, m.has_technical_problems ? 'problem' : 'message')
            } else {
              this.showEmployeeAlert(null, null, 'noone_is_coming', null, m.has_technical_problems ? 'problem' : 'message')
            }
            this.ringAllBtn.resetProgress()
          } else if (m.msg_type == 'call_all') {
            if (m.status == 'resolved') {
              this.showEmployeeAlert(m.resolved_user.avatar_url, m.resolved_user, 'is_ready_to_accept_call', null, m.has_technical_problems ? 'problem' : 'message')
            } else {
              this.showEmployeeAlert(null, null, 'no_one_is_ready_to_accept_call', null, m.has_technical_problems ? 'problem' : 'message')
            }
            this.callAllBtn.resetProgress()
          }
        })

        _.each(data.users, u => {
          u.id = u[0]
          u.status = u[1]
          u.status_changed = u[2]
          u.updated_at = u[3]

          if (this.userMap && this.userMap[u.id]) {
            this.userMap[u.id].status = u[1]

            let sduration = moment.duration(moment().diff(moment(u.status_changed)))
            this.userMap[u.id].status_changed_minutes_ago = sduration.asMinutes()
          }
        })

        this.updateRequests(data.requests, this.floors)
        this.updateEmployees(this.users, this.floors)
      },
    })
    .fail(() => {
      console.error('SI dashboard poll iteration failed')
    })
    .always(() => {
    })

    this.pollTimeout = setTimeout(() => this.poll(), this.pollInterval)
  }

  pausePolling() {
    this.statusChangeInProgress = true
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout)
    }
  }

  restorePolling() {
    this.statusChangeInProgress = false
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout)
    }
    this.pollTimeout = setTimeout(() => this.poll(), this.pollInterval)
  }

  addOfflineTerminalTooltips() {
    const marker = $(`[data-terminal-status='offline']`)
    const _this = this
    tippy(marker[0], {
      placement: 'left',
      arrow: true,
      interactive: true,
      trigger: 'click',
      size: 'large',
      theme: 'foundation-light',
      html: $('<p></p>')[0],
      onShow: function() {
        const offterms = _this.infoterminalsOffline
        const $content = $(this).find('.tippy-content')
        $content.empty()

        _.each(offterms, off => {
          const $clone = $(`[data-clone='terminal-status-item']`).clone()
          $clone.find(`[data-terminal-name]`).text(off.label)
          $content.append($clone)
          $clone.show()
        })

      },
      onHide: function() {
      }
    })
  }

  updateOfflineTerminalsArea() {
    const $online = $(`[data-terminal-status='online']`)
    const $offline = $(`[data-terminal-status='offline']`)
    if (!this.infoterminalsOffline || this.infoterminalsOffline.length == 0) {
      $online.show()
      $offline.hide()
    } else {
      $online.hide()

      const $single = $(`[data-terminal-offline-text='single']`)
      const $multiple = $(`[data-terminal-offline-text='multiple']`)

      if (this.infoterminalsOffline.length == 1) {
        $multiple.hide()
        $single.show()
      } else {
        $multiple.show()
        $single.hide()
      }

      const $num = $multiple.find('[data-num]')
      $num.text(this.infoterminalsOffline.length)

      $offline.show()
    }
  }

  updateEntities(opts) {
    const individualSelector = (id) => opts.container.find(`.${opts.itemClass}[data-entity-id=${id}]`)

    // Update/Add existing
    _.each(opts.entities, (ent, i) => {
      const $item = individualSelector(ent.id)
      if ($item.length) {
        opts.update($item, ent)
      } else {
        const $newItem = opts.clone.clone()
        $newItem.hide()
        opts.update($newItem, ent, true)
        opts.container.append($newItem)
      }
    })

    // Remember and fade out nonexistent
    let removedItems = []
    opts.container.find(`.${opts.itemClass}[data-entity-id]`).each(function() {
      const $item = $(this)
      const id = $item.data('entity-id')
      if (_.findIndex(opts.entities, e => e.id == id) == -1) {
        removedItems.push($item)
        $item.fadeOut()
      }
    })

    // Remove faded nonexistent and sort items after the fade interval.
    // NOTE: we sort after fade interval, so that if some items 'jump',
    // they are able to fully fade out before that.
    setTimeout(() => {
      let sorted = _.sortBy(opts.entities, e => opts.compareFn(e))
      if (opts.sortReverse) {
        sorted = sorted.reverse()
      }
      _.each(removedItems, r => r.remove())
      _.each(sorted, (s, i) => individualSelector(s.id).css('order', i))
    }, opts.fadeInterval)
  }

  updateItem($item, data, isNew, opts) {
    if (isNew) {
      $item.attr('data-entity-id', data.id)
      opts.updateAttributes($item, data)
      setTimeout(() => $item.fadeIn(opts.fadeInterval), opts.fadeInterval)
    } else {
      if (opts.fadeSequence && opts.fadeSequence($item, data)) {
        $item.fadeOut(opts.fadeInterval)
        setTimeout(() => {
          opts.updateAttributes($item, data)
          $item.fadeIn(opts.fadeInterval)
        }, opts.fadeInterval)
      } else {
        opts.updateAttributes($item, data)
      }
    }
  }


  addVisualSwitchHandlers() {
    $('[data-change-visual]').click(function() {
      const nextVisual = $(this).data('change-visual')
      /*$(this).closest('.card').fadeOut(() => {
        $(nextVisual).fadeIn()
      })*/
      $(this).closest('.card').hide()
      $(nextVisual).show()
    })
  }

  showLanguageModal(varName, nextModal) {
    const cl = $('.l-clone-container').find('.language-selection-modal').clone()

    this.selectedLanguage = $('[data-default-language]').data('default-language')
    if (this.selectedLanguage === undefined) {
      this.selectedLanguage = 'de'
    }

    vex.dialog.confirm({
      unsafeMessage: cl.wrap('<div/>').parent().html(),
      callback: (value) => {
        if (value) {
          this.inputs[varName] = this.selectedLanguage

          if (nextModal) {
            nextModal()
          }
        }
      }
    })
  }

  showFloorModal(varName, nextModal) {
    const cl = $('.l-clone-container').find('.floor-selection-modal').clone()
    this.selectedFloor = 'All'

    vex.dialog.confirm({
      unsafeMessage: cl.wrap('<div/>').parent().html(),
      callback: (value) => {
        if (value) {
          this.inputs[varName] = ('' + this.selectedFloor).toLowerCase()

          if (nextModal) {
            nextModal()
          }
        }
      }
    })
  }

  showInputModals(inputs, resolve, reject) {
    this.inputs = {}

    const iterate = (inp, ind) => {
      if (ind >= inp.length) {
        resolve()
        return
      }

      if (inp[ind].includes('LANGUAGE')) {
        this.showLanguageModal(inp[ind], () => iterate(inp, ind + 1))
      } else if (inp[ind].includes('FLOOR')) {
        this.showFloorModal(inp[ind], () => iterate(inp, ind + 1))
      } else {
        throw new Error('Input not supported: ' + inp[ind])
      }
    }

    iterate(inputs, 0)
  }

  // ====================================================================================
  // Requests
  // ====================================================================================



  updateRequests(requests, floors) {
    const fadeInterval = 500

    _.each(requests, r => {
      // requests: requests.pluck(:id, :status, :user_id, :voucher, :drink, :auto_notify_message_id, :table_number, :language, :infoterminal_id, :si_desk_id),
      r.id = r[0]
      r.status = r[1]
      r.user_id = r[2]
      r.voucher = r[3]
      r.drink = r[4] ? i18n[window.currentLocale].activerecord.attribute_values.terminal_strings.drinks[r[4]] : null
      r.auto_notify_message_id = r[5]
      r.table_number = r[6]
      r.language = r[7]
      r.infoterminal_id = r[8]
      r.arrived_si_desk_id = r[9]
      r.created_at = r[10]
      r.si_selected_floor = r[11]
      r.topic = r[12]
      r.customer_metadata = r[13]
    })

    _.each(floors, f => {
      const ft = f == null ? '?' : f

      const reqContainer = $(`[data-requests-container="${ft}"]`)

      const freq = _.filter(requests, u => u.floor == f)

      if (freq.length) {
        reqContainer.show()
      } else {
        reqContainer.hide()
        reqContainer.find('.si-dashboard-requests').empty()
      }

      this.updateEntities({
        entities: freq,
        container: $(`[data-requests-main="${ft}"]:visible`),
        itemClass: 'request-item',
        clone: $('.l-clone-container [data-request-clone]'),
        fadeInterval: fadeInterval,
        sortReverse: false,
        compareFn: d => {
          let v = +moment(d.created_at)
          if (d.status == 'escalated') {
            v -= +moment('2500-01-01')
          }
          return v
        },
        update: ($item, data, isNew) => {
          this.updateItem($item, data, isNew,
            {
              fadeInterval: fadeInterval,
              fadeSequence: ($item, data) => {
                const istat = $item.attr('data-status')
                return istat != data.status &&
                       (istat == 'escalated' || data.status == 'escalated')
              },
              updateAttributes: ($item, data) => {

                const set = (what, val) => $item.find('.request-item__' + what).text(val)
                $item.attr('data-status', data.status)
                $item.attr('class', `request-item ${data['user_id'] ? 'is-assigned' : ''} request-item--${data.status} ` +
                                    `${!data['user_id'] && data['auto_notify_message_id'] ? 'is-blinking' : ''}`)
                $item.removeAttr('data-request-clone')

                const statusesVisible = $('.request-item-status-visibility:visible')

                if (statusesVisible.length) {
                  $item.find('.request-item__status').hide()
                  $item.find(`.request-item__status[data-status="${data.status}"]`).show()
                }

                set('time', moment(data.created_at).format('HH:mm'))
                set('voucher-time', moment(data.created_at).format('HH:mm'))
                set('voucher', data.voucher)
                set('drink', data.drink)

                const drinkArea = $item.find('.request-item__drink-area')
                if (!data.drink) {
                  drinkArea.hide()
                } else {
                  drinkArea.show()
                }

                const photo = $item.find('.request-item__user-photo')
                const userName = $item.find('.request-item__user')
                const coming = $item.find('.request-item__coming')
                const noone = $item.find('.request-item__noone')
                const notifying = $item.find('.request-item__notifying')
                const noOneToNotify = $item.find('.request-item__no-one-to-notify')
                const floorValue = $item.find('.request-item__floor-value')
                const floorIcon = $item.find('.request-item__floor-icon')

                if (data.user_id) {
                  photo.show()
                  coming.show()
                  userName.show()
                  noone.hide()
                  notifying.hide()
                  noOneToNotify.hide()

                  const usr = this.userMap[data.user_id]
                  if (usr) {
                    photo.find('img').attr('src', usr.avatar_url)
                    set('user-name', usr.first_name + ' ' + usr.last_name)
                  }
                } else {
                  if (data['auto_notify_message_id']) {
                    photo.hide()
                    coming.hide()
                    userName.hide()
                    noone.hide()

                    if (data.status == 'escalated' || data['auto_notify_message_id'] || this.hasAvailableEmployees) {
                      notifying.show()
                      noOneToNotify.hide()
                    } else {
                      notifying.hide()
                      noOneToNotify.show()
                    }
                  } else {
                    photo.hide()
                    coming.hide()
                    userName.hide()
                    notifying.hide()
                    noOneToNotify.hide()
                    noone.show()
                  }
                }

                if (!this.shop || !this.shop.tables_enabled) {
                  $item.find('.request-item__table').hide()
                }

                if (data.table_number) {
                  $item.find('.request-table-selector__value').html(data.table_number)
                }

                const status = data.status

                $item.find('.request-item__action').removeClass('is-active')
                $item.find('.progress-btn').addClass('is-disabled')
                $item.find(`.request-item__action[data-status="${status}"]`).addClass('is-active')
                     .find('.progress-btn').removeClass('is-disabled').addClass('is-inactive')

                const allowChangeStates = (from, to) => {
                  if (from.includes(status)) {
                    _.each(to, t => $item.find(`.request-item__action[data-status="${t}"] .progress-btn`).removeClass('is-disabled'))
                  }
                }

                const alwaysShownStatuses = ['resolved', 'gone']
                const regularStatuses = ['voucher', 'arrived', 'not_arrived', 'escalated']
                const waStatuses = ['wa_call_allowed', 'wa_qualified', 'wa_unreached', 'wa_escalated']

                let hasPhoneHelperBubble = false

                if (data.status.includes('wa_')) {
                  regularStatuses.forEach(s => $item.find(`.request-item__action[data-status="${s}"]`).remove())

                  $item.find('.request-item__noone').hide()
                  $item.find('.request-item__notifying').hide()
                  $item.find('.request-item__topic').show()

                  if (data.voucher) {
                    // If data.voucher is a phone number, show the phone helper bubble
                    if (data.voucher.match(/^\+?\d+$/)) {
                      hasPhoneHelperBubble = true

                      var qrcode = new QRCode({
                        content: `tel:${data.voucher}`,
                        padding: 0,
                        width: 72,
                        height: 72,
                        color: "#fff",
                        background: "#000",
                        ecl: "M"
                      });

                      $item.find('.request-item__bubble-qr')[0].innerHTML = qrcode.svg();
                    }
                  }

                  const additional_topic = data.customer_metadata?.topic ? ` > ${data.customer_metadata.topic}` : ''

                  if (data.topic == 'new_kitchen') {
                    $item.find('.request-item__topic').text(i18n[window.currentLocale].views.dashboard_si.new_kitchen + additional_topic)
                  } else {
                    $item.find('.request-item__topic').text(i18n[window.currentLocale].views.dashboard_si.other_question + additional_topic)
                  }
                } else {
                  waStatuses.forEach(s => $item.find(`.request-item__action[data-status="${s}"]`).remove())
                }

                if (!hasPhoneHelperBubble) {
                  $item.find('.request-item__bubble').remove()
                }

                allowChangeStates(['voucher'], ['arrived', 'not_arrived'])
                allowChangeStates(['escalated'], ['resolved', 'gone'])
                allowChangeStates(['wa_call_allowed'], ['wa_qualified', 'wa_unreached', 'resolved', 'gone'])
                allowChangeStates(['wa_unreached'], ['wa_qualified', 'resolved', 'gone'])
                allowChangeStates(['wa_qualified'], ['wa_escalated', 'resolved', 'gone'])
                allowChangeStates(['wa_escalated'], ['resolved', 'gone'])

                if (data.user_id > 0) {
                  allowChangeStates(['arrived'], ['resolved', 'gone'])
                }
                else {
                  allowChangeStates(['arrived'], ['escalated', 'resolved', 'gone'])
                }

                $item.find('.request-item__language > .flag').addClass(`flag--${data.language}`)

                floorValue.hide()
                floorIcon.hide()

                if (data.infoterminal_id) {
                  set('terminal', this.infoterminals[data.infoterminal_id] ? this.infoterminals[data.infoterminal_id].label : '?')
                } else {
                  if (this.si_desks[data.arrived_si_desk_id]) {
                    set('terminal', this.si_desks[data.arrived_si_desk_id].label)
                    if (data.si_selected_floor) {
                      set('floor-value', data.si_selected_floor)
                      floorValue.show()
                      floorIcon.show()
                    }
                  }
                  else {
                    set('terminal', '?')
                  }
                }
              }
            }
          )
        }
      })
    })
  }

  addRequestActionHandlers() {
    let _this = this
    const langSelector = '.vex .language-selection-modal__language'

    $('body').on('click', langSelector, function() {
      $(langSelector).removeClass('is-active')
      $(this).addClass('is-active')
      _this.selectedLanguage = $(this).data('language')
    })

    const floorSelector = '.vex .floor-selection-modal__floor'

    $('body').on('click', floorSelector, function() {
      $(floorSelector).removeClass('is-active')
      $(this).addClass('is-active')
      _this.selectedFloor = $(this).data('floor')
    })

    const tableSelector = '.vex .table-selection-modal__table'

    $('body').on('click', tableSelector, function() {
      $(tableSelector).removeClass('is-active')
      $(this).addClass('is-active')
      _this.selectedTable = $(this).data('table')
    })

    const assignTableBtn = new ProgressButton({
      topEl: $('#open-requests'),
      childSelector: '[data-btn-action="assign-table"]',
      method: 'PATCH',
      timeout: 3000,
      url: () => '/areas/si/change_state',
      promise: $el => {
        return new Promise((resolve, reject) => {
          const cl = $('.l-clone-container').find('.table-selection-modal').clone()
          _this.selectedTable = +$el.find('.request-table-selector__value').html()

          cl.find(`[data-table=${_this.selectedTable}]`).addClass('is-active')

          vex.dialog.confirm({
            unsafeMessage: cl.wrap('<div/>').parent().html(),
            callback: (value) => {
              if (value) {
                resolve()
              } else {
              }
            }
          })
        })
      },
      data: $el => {
        return {
          shop_id: this.storeId,
          si_desk_id: this.siDeskId,
          id: $el.closest('[data-entity-id]').data('entity-id'),
          table_number: this.selectedTable,
          revision: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        }
      },
      success: data => {
        this.updateRequests(data.requests, this.floors)
      }
    })

    const createVoucherBtn = new ProgressButton({
      el: $(`[data-btn-action="create-voucher"]`),
      method: 'GET',
      timeout: 3000,
      url: () => `/areas/si/create_voucher?shop_id=${this.storeId}&si_desk_id=${this.siDeskId}&inputs=${JSON.stringify(this.inputs)}`,
      promise: $el => {
        return new Promise((resolve, reject) => {
          if (this.routingStrategies && this.routingStrategies.length) {
            const str = _.find(this.routingStrategies, rs => rs.action == 'si_request')
            if (str) {
              this.showInputModals(JSON.parse(str.data).inputs, resolve, reject)
            } else {
              resolve()
            }
          } else {
            resolve()
          }
        })
      },
      success: data => {
        this.updateRequests(data.requests, this.floors)
        this.restorePolling()
      }
    })

    this.resetWatchBtn = new ProgressButton({
      el: $(`[data-btn-action="reset_watch"]`),
      method: 'GET',
      timeout: 3000,
      manualProgressChange: true,
      url: () => `/areas/si/reset_watch?shop_id=${this.storeId}&si_desk_id=${this.siDeskId}&inputs=${JSON.stringify(this.inputs)}`,
      promise: $el => {
        return new Promise((resolve, reject) => {
          vex.dialog.confirm({
            message: 'Are you sure?',
            callback: (value) => {
              if (value) {
                resolve()
              } else {
                reject()
              }
            }
          })
        })
      },
      success: data => {
        this.waitingForOcpCommands[data.command.id] = 'waiting'
      }
    })

    new ProgressButton({
      topEl: $('#open-requests'),
      childSelector: '[data-btn-action="request-change-status"]',
      method: 'PATCH',
      timeout: 3000,
      url: `/areas/si/change_state?shop_id=${this.storeId}&si_desk_id=${this.siDeskId}`,
      promise: $el => {
        return new Promise((resolve, reject) => {
          const status = $el.closest('.request-item__action').data('status')

          if (status == $el.closest('.request-item').data('status')) {
            reject('Same status')
            return
          }

          if (status == 'gone' || status == 'resolved' || status == 'not_arrived') {
            vex.dialog.confirm({
              message: i18n[window.currentLocale].views.dashboard_si[`sure_${status}`],
              callback: (value) => {
                if (value) {
                  resolve()
                } else {
                  reject()
                }
              }
            })
          } else {
            resolve()
          }
        })
      },
      onReject: () => {
        this.restorePolling()
      },
      data: $el => {
        return {
          id: $el.closest('[data-entity-id]').data('entity-id'),
          status: $el.closest('[data-status]').data('status'),
          revision: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        }
      },
      before: () => {
        this.pausePolling()
      },
      success: data => {
        this.updateRequests(data.requests, this.floors)
      },
      always: () => {
        this.restorePolling()
      }
    })
  }

  // ====================================================================================
  // Employees
  // ====================================================================================



  updateEmployees(employees, floors) {
    const fadeInterval = 500
    const isOnline = (s) => ['a', 'b'].includes(s)
    const _this = this

    this.hasAvailableEmployees = _.findIndex(employees, e => e.status == 'a') !== -1

    const getEmployeeStatus = (d) => {
      let status = d.status

      if (d.teams_status == 'Busy') {
        status = 'busy_in_teams'
        console.log('busy in teams')
      }

      const sw = this.userSmartwatches ? this.userSmartwatches[d.id] : null
      if (sw && sw.has_technical_problems) {
        status = 'c'
      }
      return status
    }

    _.each(floors, f => {
      const ft = f == null ? '?' : f
      const usrContainer = $(`[data-employees-container="${ft}"]`)
      const fusr = _.filter(employees, u => u.routing_floor == f)

      if (fusr.length) {
        usrContainer.show()
      } else {
        usrContainer.hide()
      }

      this.updateEntities({
        entities: fusr,
        container: $(`[data-employees-main="${ft}"]:visible`),
        itemClass: 'employee-item',
        clone: $('.l-clone-container [data-employee-clone]'),
        fadeInterval: fadeInterval,
        compareFn: d => {
          let v = d.last_name
          v = getEmployeeStatus(d) + v
          return v
        },
        update: ($item, data, isNew) => {
          this.updateItem($item, data, isNew,
            {
              fadeInterval: fadeInterval,
              fadeSequence: ($item, data) => {
                return $item.attr('data-employee-status') != getEmployeeStatus(data)
              },
              updateAttributes: ($item, data) => {
                const set = (what, val) => $item.find('.employee-item__' + what).text(val)
                _.each(['a', 'b', 'c', 'p'], s => $item.removeClass(`employee-item--${s}`))

                let status = getEmployeeStatus(data)

                $item.addClass(`employee-item--${status}`)
                $item.attr('data-employee-status', status)
                $item.find('.employee-item__status').attr('class', `employee-item__status employee-item__status--${status}`)

                let timeBusy = ''

                if (status == 'b' || status == 'p' || status == 'busy_in_teams') {
                  const changedAgo = data['status_changed_minutes_ago']

                  if (changedAgo < 1) {
                    timeBusy = ' (<1m)'
                  } else {
                    timeBusy = ` (${changedAgo.toFixed(0)}m)`
                  }
                }

                set('status-text', isOnline(status) || status == 'p' || status == 'busy_in_teams' ? i18n[window.currentLocale].views.dashboard_si.employee_texts[status] + timeBusy : '')
                $item.find('.employee-item__photo img').attr('src', data.avatar_url)
                set('name', data.first_name[0] + '. ' + data.last_name)
                set('battery', '')

                const batteryContainer = $item.find('.employee-item__battery-container')
                const mobileIconBase = batteryContainer.find("[data-device='mobile']").first().clone()
                const swIconBase = batteryContainer.find("[data-device='watch']").first().clone()

                batteryContainer.empty()

                _.each(data.mobile_sessions, session => {
                  const icon = mobileIconBase.clone()
                  icon.css('display', 'inline-block')

                  if (session.status === 'online') {
                    icon.addClass('employee-item__battery-icon-online')
                  } else if (session.status === 'offline') {
                    icon.addClass('employee-item__battery-icon-offline')
                  }
                  const version = $('<span>').text(session.app_version)
                  const iconTextWrapper = $('<div>')

                  if (session.has_technical_problems) {
                    iconTextWrapper.addClass('employee-item__battery-text-technical-problems')
                  } else {
                    iconTextWrapper.addClass('employee-item__battery-text')
                  }

                  iconTextWrapper.append(icon).append(version)
                  batteryContainer.append(iconTextWrapper)
                })

                const checkBtn = $item.find('[data-btn-action="employee-check"]')
                const ringBtn = $item.find('[data-btn-action="employee-ring"]')
                const callBtn = $item.find('[data-btn-action="employee-call"]')

                if (status != 'b') {
                  checkBtn.addClass('is-disabled')
                } else {
                  checkBtn.removeClass('is-disabled')
                }

                if (status != 'a') {
                  ringBtn.addClass('is-disabled')
                  callBtn.addClass('is-disabled')
                } else {
                  ringBtn.removeClass('is-disabled')
                  callBtn.removeClass('is-disabled')
                }
              }
            }
          )
        }
      })
    })
  }

  addEmployeeActionHandlers() {
    const _this = this
    const ringTimeout = 40000

    this.ringAllBtn = new ProgressButton({
      el: $(`[data-btn-action="employee-ring-all"]`),
      method: 'GET',
      timeout: ringTimeout,
      manualProgressChange: true,
      url: () => `/areas/si/employee_ring_all?shop_id=${this.storeId}&si_desk_id=${this.siDeskId}&inputs=${JSON.stringify(this.inputs)}`,
      promise: $el => {
        return new Promise((resolve, reject) => {
          if (this.routingStrategies && this.routingStrategies.length) {
            const str = _.find(this.routingStrategies, rs => rs.action == 'ring_all')
            if (str) {
              this.showInputModals(JSON.parse(str.data).inputs, resolve, reject)
            } else {
              resolve()
            }
          } else {
            resolve()
          }
        })
      },
      success: data => {
        this.waitingForMessages[data.message.id] = 'waiting'
      }
    })

    this.callAllBtn = new ProgressButton({
      el: $(`[data-btn-action="employee-call-all"]`),
      method: 'GET',
      timeout: ringTimeout,
      manualProgressChange: true,
      url: () => `/areas/si/employee_call_all?shop_id=${this.storeId}&si_desk_id=${this.siDeskId}&inputs=${JSON.stringify(this.inputs)}`,
      promise: $el => {
        return new Promise((resolve, reject) => {
          if (this.routingStrategies && this.routingStrategies.length) {
            const str = _.find(this.routingStrategies, rs => rs.action == 'call_all')
            if (str) {
              this.showInputModals(JSON.parse(str.data).inputs, resolve, reject)
            } else {
              resolve()
            }
          } else {
            resolve()
          }
        })
      },
      success: data => {
        this.waitingForMessages[data.message.id] = 'waiting'
      }
    })

    this.ringBtn = new ProgressButton({
      topEl: $('.employees-container'),
      childSelector: '[data-btn-action="employee-ring"]',
      method: 'GET',
      manualProgressChange: true,
      timeout: ringTimeout,
      url: $el => `/areas/si/employee_ring?id=${$el.closest('[data-entity-id]').data('entity-id')}&shop_id=${this.storeId}` +
                  `&si_desk_id=${this.siDeskId}`,
      success: data => {
        this.waitingForMessages[data.message.id] = 'waiting'
      }
    })

    this.callBtn = new ProgressButton({
      topEl: $('.employees-container'),
      childSelector: '[data-btn-action="employee-call"]',
      method: 'GET',
      manualProgressChange: true,
      timeout: ringTimeout,
      url: $el => `/areas/si/employee_call?id=${$el.closest('[data-entity-id]').data('entity-id')}&shop_id=${this.storeId}` +
                  `&si_desk_id=${this.siDeskId}`,
      success: data => {
        this.waitingForMessages[data.message.id] = 'waiting'
      }
    })

    this.checkBtn = new ProgressButton({
      topEl: $('.employees-container'),
      childSelector: '[data-btn-action="employee-check"]',
      method: 'GET',
      manualProgressChange: true,
      timeout: ringTimeout,
      url: $el => `/areas/si/employee_check?id=${$el.closest('[data-entity-id]').data('entity-id')}&shop_id=${this.storeId}` +
                  `&si_desk_id=${this.siDeskId}`,
      success: data => {
        this.waitingForMessages[data.message.id] = 'waiting'
      }
    })
  }

  showEmployeeAlert(photo_url, user, translation_id, msgClass, iconClass) {
    const cl = $('.l-clone-container').find('.employee-action-reply').clone()
    const $photo = cl.find('.employee-action-reply__photo')
    const $icons = cl.find('.employee-action-reply__icon')
    const $name = cl.find('.employee-action-reply__name')
    const $msg = cl.find('.employee-action-reply__message')
    if (photo_url) {
      $photo.find('img').attr('src', photo_url)
      $icons.remove()
    } else {
      $photo.remove()
    }

    if (user) {
      $name.text(`${user.first_name} ${user.last_name}`)
    }
    $msg.text(i18n[window.currentLocale].views.dashboard_si.employee_response[translation_id])
    if (msgClass) {
      $msg.addClass(`employee-action-reply__message--${msgClass}`)
    }

    if (!iconClass) {
      iconClass = 'message'
    }

    $icons.hide()
    cl.find(`.employee-action-reply__icon--${iconClass}`).show()

    vex.dialog.alert({
      unsafeMessage: cl.wrap('<div/>').parent().html()
    })

    cl.remove()
  }


  // ====================================================================================
  // Event feed
  // ====================================================================================


  processEventFeedData(reqs) {
    let requestMap = {}
    let historyEntries = []
    this.eventRequestMap = requestMap

    let sorted = _.sortBy(reqs, r => +moment(r.created_at)).reverse

    _.each(reqs, (r, i) => {
      requestMap[r.id] = r
      r.index = i + 1
      _.each(r.request_history_entries, rhe => {
        historyEntries.push(rhe)
        rhe.request = r
      })
    })
    return historyEntries
  }

  updateEvents(events) {
    const fadeInterval = 500

    this.updateEntities({
      entities: events,
      container: $('[data-events-main]'),
      itemClass: 'event-item',
      clone: $('.l-clone-container [data-event-clone]'),
      fadeInterval: fadeInterval,
      sortReverse: true,
      compareFn: d => {
        return +moment(d.time)
      },
      update: ($item, data, isNew) => {
        this.updateItem($item, data, isNew,
          {
            fadeInterval: fadeInterval,
            updateAttributes: ($item, data) => {
              const set = (what, val) => $item.find('.event-item__' + what).text(val)
              const request = this.eventRequestMap[data.request_id]

              $item.attr('data-event-index', request.index)
              $item.attr('class', `event-item event-item--${data.status}`)

              set('voucher', request.voucher && request.voucher.length ? request.voucher : '—————')
              set('voucher-id', request.index)
              set('header-time', moment(data.time).format('HH:mm'))
              set('time', moment(data.time).format('HH:mm'))
              set('floor-value', request.floor)

              if (request.user_id !== undefined && request.user_id !== null) {
                $item.find('.event-item__user').removeClass('no-user')
                const usr = this.userMap[request.user_id]
                if (usr) {
                  set('user-name', `${usr.first_name[0]}. ${usr.last_name}`)
                  $item.find('.event-item__user-avatar img').attr('src', usr.avatar_url)
                }
              } else {
                $item.find('.event-item__user').addClass('no-user')
              }

              if (request.infoterminal_id) {
                set('terminal-id', 'T' + request.infoterminal_short_id)
              } else {
                set('terminal-id', request.arrived_si_desk_label)
              }

              $item.find('.flag').attr('class', `flag flag--${request.language} flag--s`)

              $item.find('[data-event-status]').hide()
              $item.find(`[data-event-status="${data.status}"]`).css('display', 'flex')

              let text = ''
              if (data.status == 'not_served' && request.infoterminal_id) {
                text = i18n[window.currentLocale].views.dashboard_si.events.open_terminal.replace('%{infoterminal_short_id}', request.infoterminal_short_id)
                                                                                         .replace('%{infoterminal_label}', request.infoterminal_label)
                                                                                         .replace('%{floor}', request.floor)
              } else if (data.status == 'voucher' && request.arrived_si_desk_id) {
                text = i18n[window.currentLocale].views.dashboard_si.events.open_si_desk.replace('%{si_desk_label}', request.arrived_si_desk_label)
                                                                                        .replace('%{floor}', request.floor)
              } else if (data.status == 'voucher') {
                text = i18n[window.currentLocale].views.dashboard_si.events.voucher.replace('%{voucher}', request.voucher)
              } else if (['arrived', 'escalated', 'gone', 'resolved', 'not_arrived', 'served', 'keep_looking', 'wa_call_allowed', 'wa_qualified', 'wa_escalated'].includes(data.status)) {
                text = i18n[window.currentLocale].views.dashboard_si.events[data.status] ?
                       i18n[window.currentLocale].views.dashboard_si.events[data.status] :
                       i18n['en'].views.dashboard_si.events[data.status]
              }
              set('body', text ? text : '')
            }
          }
        )
      }
    })
  }

  updateHighlightedEvents() {
    const $dataEvents = $('[data-event-index]')

    if (this.isHighlightingEvents) {
      $dataEvents.addClass('is-faded')
      $(`[data-event-index='${this.highlightedRequestId}'`).removeClass('is-faded')
    } else {
      $dataEvents.removeClass('is-faded')
    }
  }

  addEventHandlers() {
    const _this = this
    $('.si-dashboard-events').on('click', '[data-event-index]', function(e) {
      if (!_this.isHighlightingEvents) {
        _this.highlightedRequestId = $(this).data('event-index')
      }
      _this.isHighlightingEvents = !_this.isHighlightingEvents
      _this.updateHighlightedEvents()
    })
  }
}

export default PageSiDashboard
